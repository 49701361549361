<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100 w-reset">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.orders") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <el-button
          size="mini"
          v-can="'patients.create'"
          @click="drawerExpired = true"
        >
          {{ $t("message.expired")
          }}<span style="color:#FF4545"> ( {{ count }} )</span>
        </el-button>
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
         <crm-column-settings
          :columns="columns"
          :modelName="'orders'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.total_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.paid_price"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.status"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.user"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.partner_doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.partner_clinic_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.doctor_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.laborant"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <!-- <crm-th
            :column="columns.yes_doctor"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th> -->
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              clearable
              size="mini"
              class="d-block"
              v-model="filterForm.patient_id"
              :placeholder="$t('message.patient')"
            ></el-input>
          </th>
          <th v-if="columns.total_price.show"></th>
          <th v-if="columns.paid_price.show"></th>
          <th v-if="columns.status.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.status.title"
              size="mini"
              v-model="filterForm.step"
            >
              <el-option
                :label="$t(`message.status_waiting_paid`)"
                :value="'waiting_paid'"
              ></el-option>
              <el-option
                :label="$t(`message.status_laborant_free`)"
                :value="'laborant_free'"
              ></el-option>
              <el-option
                :label="$t(`message.status_laborant`)"
                :value="'laborant'"
              ></el-option>
              <el-option
                :label="$t(`message.status_doctor_free`)"
                :value="'doctor_free'"
              ></el-option>
              <el-option
                :label="$t(`message.status_doctor`)"
                :value="'doctor'"
              ></el-option>
              <el-option
                :label="$t(`message.status_written`)"
                :value="'written'"
              ></el-option>
              <el-option
                :label="$t(`message.status_closed`)"
                :value="'closed'"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.user.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.user.title"
              size="mini"
              v-model="filterForm.user_id"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="(item.surname || '') + ' ' + (item.name || '')"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.partner_doctor_id.show">
            <el-input
              clearable
              size="mini"
              class="d-block"
              v-model="filterForm.partner_doctor"
              :placeholder="$t('message.partner_doctor')"
            ></el-input>
          </th>
          <th v-if="columns.partner_clinic_id.show">
            <el-input
              clearable
              size="mini"
              class="d-block"
              v-model="filterForm.partner_clinic"
              :placeholder="$t('message.partner_clinic')"
            ></el-input>
          </th>
          <th v-if="columns.doctor_id.show">
            <el-input
              clearable
              size="mini"
              class="d-block"
              v-model="filterForm.doctor"
              :placeholder="$t('message.doctor')"
          ></el-input></th>
          <th v-if="columns.laborant.show"></th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              size="mini"
            ></el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="(order, index) in list"
          class="cursor-pointer"
          :key="'sss' + index"
        >
          <td v-if="columns.id.show">{{ order.id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              order.patient
                ? order.patient.surname + " " + order.patient.first_name
                : ""
            }}
          </td>
          <td v-if="columns.total_price.show">
            {{ order.total_price | formatMoney }}
          </td>
          <td v-if="columns.paid_price.show">{{ order.paid | formatMoney }}</td>

          <td v-if="columns.status.show">
            {{ order.step ? $t("message.status_" + order.step) : "" }}
          </td>
          <td v-if="columns.user.show">
            {{ order.user ? order.user.surname : "" }}
            {{ order.user ? order.user.name : "" }}
          </td>
          <td v-if="columns.partner_doctor_id.show">
            {{ order.partner_doctor ? order.partner_doctor.name : "" }}
          </td>
          <td v-if="columns.partner_clinic_id.show">
            {{ order.partner_clinic ? order.partner_clinic.name : "" }}
          </td>
          <td v-if="columns.doctor_id.show">
            {{
              order.doctor ? order.doctor.surname + " " + order.doctor.name : ""
            }}
          </td>
          <td v-if="columns.laborant.show">
            {{
              order.laborant
                ? order.laborant.surname + " " + order.laborant.name
                : ""
            }}
          </td>
          <td v-if="columns.created_at.show">{{ order.created_at }}</td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button
              round
              @click="show(order)"
              size="mini"
              type="primary"
              icon="el-icon-view"
            ></el-button>
            <el-button
              round
              :disabled="order.edit_status"
              @click="edit(order)"
              type="primary"
              icon="el-icon-edit"
            ></el-button>
          </td>
        </tr>
      </transition-group>
    </table>
    <el-drawer
      :visible.sync="drawerOrderShow"
      size="80%" :wrapperClosable="false"
      :drawer="drawerOrderShow"
    >
      <crm-show
        @c-close="closeDrawer"
        :order="selectedItem"
        :open="drawerOrderShow"
        drawer="drawerOrderShow"
      ></crm-show>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerOrderUpdate"
      size="80%" :wrapperClosable="false"
      :drawer="drawerOrderUpdate"
      @closed="reloadIfChanged()"
    >
      <crm-update
        @c-close="closeDrawer"
        :order="selectedItem"
        :open="drawerOrderUpdate"
        drawer="drawerOrderUpdate"
      ></crm-update>
    </el-drawer>
    <el-drawer
      :visible.sync="drawerExpired"
      size="90%" :wrapperClosable="false"
      :drawer="drawerExpired"
      @close="reloadIfChanged"
      @opened="drawerOpened(drawer)"
    >
      <div>
        <crm-expired
          :ref="drawer"
          :reloadModel="reopenUpdate"
          :reopened="drawerExpired"
          @c-close="reloadIfChanged('drawerExpired')"
        ></crm-expired>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmShow from "./components/crm-show";
import CrmExpired from "./components/crm-expired-show";
import list from "@/utils/mixins/list";
import Update from "./components/crm-update";
import doctors from "@/components/inventory-select/doctor_list";
import drawer from "@/utils/mixins/drawer";
import users from "../../components/inventory-select/users";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",

  components: {
    "crm-show": CrmShow,
    "crm-update": Update,
    doctors,
    CrmExpired,
    users,
  },
  data() {
    return {
      drawerOrderShow: false,
      drawerExpired: false,
      drawerOrderUpdate: false,
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      selectedItem: null,
      count: null,
      drawer: "expiredDrawer",
    };
  },
  computed: {
    ...mapGetters({
      list: "orders/inventory",
      prices: "orders/prices",
      doctor_list: "users/list",
      columns: "orders/columns",
      pagination: "orders/pagination",
      filter: "orders/filter",
      sort: "orders/sort",
      users: "users/inventory_general",
    }),
    actions: function () {
      return ["edit", "delete", "show"];
    },
  },
  async mounted() {
    const query = { can: "orders.create" };
    await this.getUsers();
    this.controlExcelData();
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updateFilter(newVal);
      },
      deep: true,
    },
    sort: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
    },
    pagination: {
      handler: async function (newVal, oldVal) {
        await this.fetchData();
      },
      deep: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      updateList: "orders/inventory",
      doctorList: "users/index",
      updateSort: "orders/updateSort",
      updateFilter: "orders/updateFilter",
      updateColumn: "orders/updateColumn",
      updatePagination: "orders/updatePagination",
      empty: "orders/empty",
      refreshData: "orders/refreshData",
      getUsers: "users/inventory_general",
    }),
    async fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.count = res.data.order_service_count;
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    edit(model) {
      this.selectedItem = model;
      this.drawerOrderUpdate = true;
    },
    show(model) {
      this.selectedItem = model;
      this.drawerOrderShow = true;
    },
    // roomOrder(worktime, day) {
    //   var array = [];
    //   var get_room = worktime.filter(
    //     (val) =>
    //       val.day ==
    //       new Date(
    //         day.substr(6, 4),
    //         day.substr(3, 2),
    //         day.substr(0, 2)
    //       ).getDay()
    //   );
    //   get_room.forEach((val) => array.push(val.room.room_number));
    //   return array.toString();
    // },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
          this.fetchData();
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (
            column.show &&
            column.column !== "settings" &&
            column.column != "color"
          ) {
            switch (column.column) {
              case "patient_id":
                this.excel_fields[column.title] = {
                  field: "patient",
                  callback: (value) => {
                    return value.surname + " " + value.first_name;
                  },
                };
                break;
              case "paid_price":
                this.excel_fields[column.title] = {
                  field: "paid",
                  callback: (value) => {
                    return value;
                  },
                };
                break;
              case "partner_doctor_id":
                this.excel_fields[column.title] = {
                  field: "partner_doctor",
                  callback: (value) => {
                    return value ? value.name : "";
                  },
                };
                break;
              case "partner_clinic_id":
                this.excel_fields[column.title] = {
                  field: "partner_clinic",
                  callback: (value) => {
                    return value ? value.name : "";
                  },
                };
                break;
              case "doctor":
                this.excel_fields[column.title] = {
                  field: "doctor",
                  callback: (value) => {
                    return value ? value.name + " " + value.surname : "";
                  },
                };
                break;
              case "laborant":
                this.excel_fields[column.title] = {
                  field: "laborant",
                  callback: (value) => {
                    return value ? value.name + " " + value.surname : "";
                  },
                };
                break;
              case "order_id":
                break;
              case "status":
                this.excel_fields[column.title] = {
                  field: "step",
                  callback: (value) => {
                    return this.$t("message.status_" + value);
                  },
                };
                break;
              case "doctor_id":
                break;
              case "service":
                break;
              case "user":
                this.excel_fields[column.title] = {
                  field: "user",
                  callback: (value) => {
                    return value ? value.name + " " + value.surname : "";
                  },
                };
                break;
              default:
                this.excel_fields[column.title] = column.column;
                break;
            }
          }
        }
      }
    },
    // roomOrderExcel(worktime) {
    //   var array = [];
    //   var get_room = worktime.filter(
    //     (val) =>
    //       val.day ==
    //       new Date(
    //         val.days.substr(6, 4),
    //         val.days.substr(3, 2),
    //         val.days.substr(0, 2)
    //       ).getDay()
    //   );
    //   get_room.forEach((val) => array.push(val.room.room_number));
    //   return array.toString();
    // },
    emptyModel() {
      this.empty();
    },
  },
};
</script>

